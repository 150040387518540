import { Box, Typography } from "@mui/material"

interface HighlightTypeDescriptionProps {
    colorCode: any,
    title: string
    description: string
}

const HighlightTypeDescription = (props: HighlightTypeDescriptionProps) => {
    return (
        <Box paddingBottom={'1rem'}>
            <span style={{'height': '17px', 'width': '17px', 'backgroundColor': props.colorCode, 'borderRadius': '50%', 'display': 'inline-block'}}/>
            <Box marginLeft={'25px'} marginTop={'-25px'}>
                <Typography fontSize={'1.125rem'}>
                    {props.title}
                </Typography>
                <Typography>
                    {props.description}
                </Typography>
            </Box>
        </Box>
    )
}

export default HighlightTypeDescription