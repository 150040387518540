import { Typography } from '@mui/material';
import  React, { useState } from 'react';
import Header from '../components/NavBar/NavBar';
import { useEffect } from "react"

console.log("rendering contact us page ")
const ContactUsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Highlightly | Contact Us"
    }, [])

    const [showSignUpToNewsletter, setShowSignUpToNewsletter] = useState(true)

    return (
        <div>
            <Header showGoToTool={true} showAccountInfo={false} signOutRedirect={null} showSignUpToNewsletter={showSignUpToNewsletter} setShowSignUpToNewsletter={setShowSignUpToNewsletter}/>
            <div style={{padding: "2em"}}>
                <Typography variant='h4' textAlign='center' color='primary'>Contact Us</Typography>
                <Typography textAlign='center'>Do you have an idea, question or run into a problem?</Typography>
                <Typography textAlign='center'>Send us an email at <a href="mailto:highlightlyapplication@gmail.com">highlightlyapplication@gmail.com</a></Typography>
            </div>
        </div>
    );
}

export default ContactUsPage;
