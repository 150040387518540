import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const PageTracker = () => {
    const location = useLocation()

  useEffect(() => {
    console.log(`You changed the page to: ${location.pathname}`)
    ReactGA.send({ hitType: "pageview", page: location.pathname });
 },[location]) 

 return(
    <div></div>
 )
}

export default PageTracker