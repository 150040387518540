import { Button, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import Header from '../components/NavBar/NavBar'
import appPreview from "../graphics/appPreview.png"
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react"


const PrivacyNoticePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Highlightly | Privacy Notice"
    }, [])
    const theme = useTheme();

    const headingVariant = 'h5'
    const subHeadingVariant = 'h6'

    return (
        <div>
            <Header showGoToTool={false} showAccountInfo={true} signOutRedirect={null} showSignUpToNewsletter={false} setShowSignUpToNewsletter={() => {}}/>
            <div style={{padding: 10}}>
            <Typography  variant="h4" align='center'>
                Privacy & Cookies Notice
            </Typography>
            <br/>
            <Typography>
                This privacy policy (privacy notice) will explain how our organization uses the personal data we collect from you when you use our website, software and services.
            </Typography>

            <Typography variant={headingVariant}>
                    Topics:
            </Typography>
            <Typography>
                    <ul>
                        <li>What data do we collect?</li>
                        <li>How do we collect your data</li>
                        <li>How will we use your data?</li>
                        <li>When do we share your data?</li>
                        <li>How do we store your data</li>
                        <li>Marketing</li>
                        <li>What are your data protection rights if you are a UK user?</li>
                        <li>Cookies, web beacons and other similar technologies</li>
                        <li>Privacy Policies of other websites</li>
                        <li>Addtional policies for Nevada Users</li>
                        <li>Changes to this policy</li>
                        <li>How to contact us</li>
                        <li>How to contact the appropriate authority</li>
                    </ul>
            </Typography>

            <Typography variant={headingVariant}>
                What data do we collect?
            </Typography>
            <Typography>
                Our Company may collect the following data:
                <ul>
                    <li>Personal identification information (Name, email address, phone number, contact preferences, etc.), when you login to our service, contact us or sign up for our newsletter.</li>
                    <li>Log data. As with most websites and technology services delivered over the internet, our servers automatically collect data when you access or use our Site, Software, and/or Services and record it in log files. This log data may include IP address, browser type and settings, date and time of use, information about browser configuration, language preferences, and cookie data.</li>
                    <li>Location data. The geographic area where you connect to our service from (indicated by your IP address or similar technology)</li>
                    <li>User content, consisting of all text, documents and other content, uploaded or transmitted by you to our service.</li>
                    <li>Usage information about your use of our services, such as the pages you visit on the site. We may also obtain data from our third-party partners and service providers to analyze how users use our Site, Software, and/or Services. For example, we may know how many users access a specific page on the Site and which links they clicked on. We use this aggregated information to better understand and optimize the Site.</li>
                    <li>Device information, such as the type of hardware and software you are using (for example, your operating system and browser type).</li>
                    <li>Any information that you transfer to us when contacting us via our software, services or via our contact methods.</li>
                </ul>
            </Typography>

            <Typography variant={headingVariant}>
                How do we collect your data
            </Typography>
            <Typography>
                You directly provide Our Company with most of the data we collect. We collect data and process data when you:
                <ul>
                    <li>Register (Sign up) for an account.</li>
                    <li>Subscribe to any of our newsletters</li>
                    <li>Voluntarily complete a customer survey or provide feedback via our services or via email.</li>
                    <li>Use or view our website, software or services.</li>
                </ul>
            </Typography>

            <Typography variant={headingVariant}>
                How will we use your data?
            </Typography>
            <Typography>
                We use, process and store your information as needed to perform our contract with you as set out in our <Link to={'/termsOfService'}>terms of service</Link> and for our legitimate business interests these include:
                <ul>
                    <li>To provide and administer our site and services, authenticate users, provide user features, conduct research, improve existing and develop new features and algorithms. </li>
                    <li>To email you about your use of our software and services, make product announcements and updates. Along with responding to your queries.</li>
                    <li>Display your user content to you where this has been saved.</li>
                    <li>To calculate aggregate usage statists of our software and services.</li>
                    <li>Detect usage of our software and services outside our agreed terms.</li>
                    <li>To email you marketing emails about our products where you have agreed to receive these.</li>
                    <li>To understand how users use our website, software and services so that we can improve them</li>
                </ul>
                When Our Company processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                When do we share your data?
            </Typography>
            <Typography>
                We may share your personal data when:
                <ul>
                    <li>We have your explicit consent to do so.</li>
                    <li>We utilise a service provider to help us to meet business operation requirements, including hosting, delivering, and improving our software and services. We also use service providers for specific services and functions, including email communication, newsletters and analytics. These service providers may only access, process, or store Personal Data pursuant to our instructions and to perform their duties to us.</li>
                    <li>It is necessary as considered by us to investigate, prevent, or take action regarding violations of any of our terms.</li>
                    <li>We believe that access to, modification of or disclosure of your personal data is required by law to protect the interests of us or to respond to requests of legal authorities.</li>
                    <li>We require to do so due to or consideration of a merger, acquisition, bankruptcy, reorganization, sale of some or all of our assets or stock, public offering of securities. In this circumstance some or all of your Data may be shared with or transferred to another entity.</li>
                </ul>
                Additionally we may share non personal data publicly and to third parties, for example aggregate usage data in product reports.
                <br/>
                Our service providers include:
                <ul>
                    <li>Google Cloud platform for hosting and managing our website, software, and services.</li>
                    <li>Google G-Suite for administrative tasks including but not limited to receiving and replying to emails.</li>
                    <li>ConvertKit for providing our newsletter.</li>
                    <li>Google Analytics for understanding how users use our website, software and services (see: <a href='https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk'>Google Analytics Cookies</a> & cookies section below)</li>
                </ul>
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                How do we store your data
            </Typography>
            <Typography>
                Our Company transfers, processes, and stores data in the United Kingdom and the United States. By using our software and services you are agreeing to the information you provide to be hosted and accessed in the UK and United States.
                <br/>
                We take reasonable precautions to protect your data using industry standard encryption to protect it. We acknowledge however that internet data transmissions cannot be guaranteed to be 100% secure and therefore we do not guarantee the security of the data that you transfer to us and by doing so you acknowledge that you transfer data at your own risk.
                <br/>
                You can delete your personal data at any time by deleting your account with us. We may keep some of your personal data for as long as reasonably necessary after this however for our legitimate business interests including fraud detection and prevention and to comply with our legal obligations including tax, legal reporting, and audit obligations. 
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                Marketing
            </Typography>
            <Typography>
                Our Company would like to send you information about products and services of ours that we think you might like and to tell you about the progress that we have made to build and improve our existing products.
                <br/>
                If you have agreed to receive marketing, you may always opt out at a later date.
                <br/>
                You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other members of the Our Company Group.
                <br/>
                If you no longer wish to be contacted for marketing purposes, please contact us using the details in this document.
                <br/>
                Please note you can unsubscribe from marketing via our newsletter only by using the unsubscribe button at the bottom of the latest email we sent you or by contacting us.
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                What are your data protection rights if you are a UK user?
            </Typography>
            <Typography>
                Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                <br/>
                The right to access – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.
                <br/>
                The right to rectification – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.
                <br/>
                The right to erasure – You have the right to request that Our Company erase your personal data, under certain conditions.
                <br/>
                The right to restrict processing – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.
                <br/>
                The right to object to processing – You have the right to object to Our Company’s processing of your personal data, under certain conditions.
                <br/>
                The right to data portability – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                <br/>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email.
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                Cookies, web beacons and other similar technologies.
            </Typography>
            <Typography>
                Cookies (From here forward this also includes web beacons and other similar technologies) are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology, to help us provide our software and servces to you as well as to help deliver ads for relevant products and services.
                For further information, visit allaboutcookies.org.
            </Typography>
            <Typography variant={subHeadingVariant}>
                How do we use cookies?
            </Typography>
            <Typography>
                Our Company uses cookies in a range of ways to improve your experience on our website, including:
                <ul>
                    <li>Keeping you signed in</li>
                    <li>To hold session information</li>
                    <li>To store your cookie preferences</li>
                    <li>Understanding how you use our website through Google Analytics Cookies</li>
                </ul>
                Please note that our software and services currently do not respond to Do Not Track requests.
            </Typography>
            <br/>
            <Typography variant={subHeadingVariant}>
                What types of cookies do we use?
            </Typography>
            <Typography>
                There are a number of different types of cookies, however, our website uses:
                <ul>
                    <li>Functionality – Our Company uses these cookies so that we recognize you on our website and remember your previously selected preferences. These include your cookie preferences and identify to keep you loged in. A mix of first-party and third-party cookies are used.</li>
                    <li>Analytical - Our Company uses Google Analytics and its cookies to understand how you use our website, to understand how Google Analyticts cookies are used by us and Google please visit <a href='https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk'>Google Analytics Cookies</a></li>
                    <li>Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some  aspects of this data with third parties for advertising purposes and to better target advertisements and marketing, including sometimes remarketing to you when on other sites (note you can opt out of remarketing via Googls Settings page). We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
                </ul>
            </Typography>
            <Typography variant={subHeadingVariant}>
                How to manage cookies?
            </Typography>
            <Typography>
                You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result. Additionally you can use the link on our website footer at anytime to change your cookie preferences on our site to disable cookies that are not required for the core functionality of our site, software and servcies.
            </Typography>
            <Typography>

            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                Privacy Policies of other websites
            </Typography>
            <Typography>
                The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                Addtional policies for Nevada Users
            </Typography>
            <Typography>
                Under Nevada law some Nevada consumers can opt out of the sale of personally identifiable information. We do not sell personally identifiable information, however if you are a Nevada resident who has used our software or services you may opt out of any potential future sales by emailing us at the address in this policy we will then seek to verify your identity.
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                Changes to this policy
            </Typography>
            <Typography>
                Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 15 April 2022. Please not that your continued use of our software and services after any changes means that you agree with and consent to be bound by the new Policy. If you do not agree with any part of the Policy you are required to delete your account.
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                How to contact us
            </Typography>
            <Typography>
                If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
                <br/>
                Email us at: <a href="mailto:highlightlyapplication@gmail.com">highlightlyapplication@gmail.com</a>
            </Typography>
            <br/>

            <Typography variant={headingVariant}>
                How to contact the appropriate authority
            </Typography>
            <Typography>
                We believe that direct communication with us can resolve most complaints however should you wish to report a complaint or if you feel that Our Company has not addressed your concern in a satisfactory manner, you may contact the local supervisory authority.
            </Typography>
            <br/>
            </div>
        </div>
    );
}

export default PrivacyNoticePage;
