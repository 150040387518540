import { Button, Paper, Typography } from "@mui/material"
import { deleteUser, User } from "firebase/auth"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import {UserContext} from '../../../UserContext';


const DeleteAccount = () => {
    const [showConfirmDeleteAccount, setShowConfirmDeleteAccount] = useState(false)
    const [showDeleteAccountError, setShowDeleteAccountError] = useState(false)
    const [deleteAccountErrorMesssage, setDeleteAccountErrorMesssage] = useState("")
    const [showDeleteAccountSuccess, setShowDeleteAccountSuccess] = useState(false)

    const user: User|null = useContext(UserContext)
    const navigate = useNavigate();
    
    const handleDeleteAccount = () => {
        setShowConfirmDeleteAccount(true)
    }

    const handleCancelDeleteAccount = () => {
        setShowConfirmDeleteAccount(false)
    }

    const handleConfirmDeleteAccount = () => {
        if (user != null) {
            deleteUser(user).then(() => {
                setShowDeleteAccountError(false)
                setShowDeleteAccountSuccess(true)
                navigate('/')
            }).catch((error:any) => {
                setShowDeleteAccountSuccess(false)
                setDeleteAccountErrorMesssage(error.message)
                setShowDeleteAccountError(true)
            });
        }
        else {
            setDeleteAccountErrorMesssage("Login timed out please log back in before trying to change your name")
            setShowDeleteAccountError(true)
        }
    }

    return (
        <Paper elevation={2} style={{margin:'auto', marginTop:'30px', padding:10, maxWidth:400}}>
            <div>
                <Typography variant = 'h6'>Delete Account</Typography>
                <form>
                    {!showConfirmDeleteAccount && 
                        <div style={{marginTop: '8px'}}>
                            <Button onClick={handleDeleteAccount} color='error' variant='contained' fullWidth>
                                Delete Account
                            </Button>
                            <Typography variant='subtitle2' color='error'>Warning: this is not reversable</Typography>
                        </div>
                    }
                    {showConfirmDeleteAccount &&
                        <div style={{marginTop: '8px'}}>
                            <Button onClick={handleCancelDeleteAccount} variant='contained' fullWidth>
                                Cancel
                            </Button>
                            <br/>
                            <br/>
                            <Button onClick={handleConfirmDeleteAccount} color='error' variant='outlined' fullWidth>
                                Confirm Delete Account
                            </Button>
                            <Typography variant='subtitle2'  color='error'>Warning: this is not reversable</Typography>
                        </div>
                    }
                    
                    {showDeleteAccountError &&
                        <Typography variant='subtitle2' color='error'>{deleteAccountErrorMesssage}</Typography>
                    }
                    {showDeleteAccountSuccess &&
                        <Typography variant='subtitle2' color='green'>Successfully deleted account</Typography>
                    }
                </form>
            </div>
        </Paper>
    )

}

export default DeleteAccount