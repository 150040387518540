import { ContentPasteOutlined } from "@mui/icons-material";
import SummarisedReturn from "../../models/SummarisedReturn";
import{Alert, AlertTitle, Box, Button, Paper, Typography } from '@mui/material/';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { lighten, darken } from "@mui/material/styles"
import { preProcessFile } from "typescript";


interface HighlightTypeSelectorProps {
    colorCode: any,
    title: String,
    appearances: Number,
    isSelected: Boolean
    setIsSelected: Dispatch<SetStateAction<boolean>>
    isHovering: Boolean
    setIsHovering: Dispatch<SetStateAction<boolean>>
}

const HighlightTypeSelector = (props: HighlightTypeSelectorProps) => {
    const handleClick = () => {
        props.setIsSelected(!props.isSelected)
    }

    const handleHover = () => {
        props.setIsHovering(true)
    }

    const handleUnhover = () => {
        props.setIsHovering(false)
    }

    return (
        <div onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleUnhover}>
        <Paper variant="outlined" elevation={3} style={{'padding': '7px', 'width': '200px', 'backgroundColor': (props.isSelected||props.isHovering)?lighten(props.colorCode, 0.6):'', "cursor": "pointer"}}>
            <span style={{'height': '17px', 'width': '17px', 'backgroundColor': props.colorCode, 'borderRadius': '50%', 'display': 'inline-block'}}/>
            <Box marginLeft={'25px'} marginTop={'-15px'}>
                <Typography fontSize={18}>
                    {props.title}
                </Typography>
                <Typography variant="subtitle2">
                    {props.appearances} Sentences
                </Typography>
            </Box>
        </Paper>
        </div>
    )
}

export default HighlightTypeSelector