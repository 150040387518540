import logo from './logo.svg';
import React, { Component } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import ToolPage from './pages/ToolPage'
import HomePage from './pages/HomePage'
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyNoticePage from './pages/PrivacyNoticePage';
import SignUpPage from './pages/SignUpPage';
import SignIn from './pages/SignInPage';
import Footer from './components/Footer/Footer'
import { initializeApp } from 'firebase/app'
import { setPersistence, getAuth, browserLocalPersistence } from 'firebase/auth'
import AccountManagement from './pages/AccountManagementPage';
import UserProvider, { UserContext } from './UserContext';
import ResetPasswordPage from './pages/ResetPasswordPage'
import StayInformedPage from './pages/StayInformedPage'
import ReactGA from "react-ga4";
import { useState, useEffect } from "react"
import PageTracker from './components/PageTracker/PageTracker';
import CookiesDialog from './components/CookiesDialog/CookiesDialog';
import { useCookies } from 'react-cookie';
import ContactUsPage from './pages/ContactUsPage';
import { Button, Typography } from '@mui/material';
import ContactMeButton from './components/ContactMeButton/ContactMeButton';
import { useMediaQuery } from '@mui/material';
import {useTheme} from '@mui/material/styles';

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['highlightly-preferences'])

  const firebaseConfig = {
    apiKey: "AIzaSyCTUmAqCwUIhcnLmhQChjaiiZ_jRUlV5mU",
    authDomain: "highlightly.firebaseapp.com",
    projectId: "highlightly",
    storageBucket: "highlightly.appspot.com",
    messagingSenderId: "682815411621",
    appId: "1:682815411621:web:f0c13eaf40f627db7f044c"
  };

  let initalCookieState
  let initalDialogState
  if (cookies['highlightly-preferences'] != undefined) {
    // the user has previously given their preferences
    initalDialogState = false
    const cookie = cookies['highlightly-preferences']
    if (cookie.analytical == true) {
      initalCookieState = true
    }
    else {
      initalCookieState = false
    }
  }
  else {
    initalCookieState = false
    initalDialogState = true
  }
  const [analyticsAllowed, setAnalyticsAllowed] = useState(initalCookieState)
  const [showCookieDialog, setShowCookieDialog] = useState(initalDialogState)

  const setThing = (val) => {
    console.log("value: " + val)
    setAnalyticsAllowed(val)
  }

  const firebaseApp = initializeApp(firebaseConfig);
  getAuth().setPersistence(browserLocalPersistence)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#35A7FF',
        light: '#E5F4FF',
      },
      secondary: {
        main: '#38618C',
      },
      text: {
        secondary: "#6B7280"
      }
    }
  });

  useEffect(() => {
    console.log("analytics changed")
    if (analyticsAllowed && !ReactGA.isInitialized) {
      console.log("initaliszing")
      ReactGA.initialize("G-NG5FFKSWPF");  
    }
    else if (!analyticsAllowed) {
      console.log("deleting cookies")
      // need to remove the cookies that are already there for google analytics list of cookies here - https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
      for (let cookie in cookies) {
        if (cookie.startsWith("_ga") || cookie.startsWith("_gid") || cookie.startsWith("_gat") || cookie.startsWith("_gac")) {
          removeCookie(cookie)
        }
      }
    }
  },[analyticsAllowed]) 
  

  let smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
      <CookiesDialog showDialog={showCookieDialog} setShowDialog={setShowCookieDialog} setAnalyticsAllowed={setThing}/>
      <BrowserRouter>
        <div style={{minHeight: '100%', marginBottom: -25}}>
          <Routes>
            <Route path="/tool/:content" element={<ToolPage/>}/>
            <Route path="/tool" element={<ToolPage/>}/>
            <Route path="/stayInformed/privacyNotice" element={<PrivacyNoticePage/>}/>
            <Route path="/stayInformed/termsOfService" element={<TermsOfServicePage/>}/>
            <Route path="/stayInformed" element={<StayInformedPage/>}/>
            <Route path="/termsOfService" element={<TermsOfServicePage/>}/>
            <Route path="/privacyNotice" element={<PrivacyNoticePage/>}/>
            <Route path="/contactUs" element={<ContactUsPage/>}/>
            {/* <Route path="/signup" element={<SignUpPage/>}/>
            <Route path="/signin" element={<SignIn/>}/>
            <Route path='/account' element={<AccountManagement/>}/>
            <Route path='/resetPassword' element={<ResetPasswordPage/>}/> */}
            <Route path="*" element={<HomePage/>}/>
          </Routes>
          <div style={{height: '25px'}}></div>
        </div>
        <ContactMeButton/>
        <Footer setShowCookieDialog={setShowCookieDialog}/>
        {analyticsAllowed &&
          <PageTracker/>
        }
      </BrowserRouter>
      </UserProvider>
      
    </ThemeProvider>
  );
}

export default App;
