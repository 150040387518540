import { Button, Grid, Typography, styled, Card, CardContent, CardMedia, Stack, SvgIcon, Avatar, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, SwipeableDrawer } from '@mui/material';
import React, { useState } from 'react';
import appPreview from "../graphics/appPreview.png"
import avalibleInChromeWebStore from "../graphics/avalibleInChomeWebStore.png"

import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react"
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface OurStoryAccordionProps {
    centerSummary?: boolean
}

const OurStoryAccordion = (props: OurStoryAccordionProps) => {
    const StyledAccordionSummary = styled(AccordionSummary)`
    &:hover {
        background-color: ${props => props.theme.palette.primary.light};
    },
    `

    const [ourStoryExpanded, setOurStoryExpanded] = useState(false)

    const ourStoryExpandedChange = (event: React.SyntheticEvent, expanded: boolean) => {
        setOurStoryExpanded(expanded)
    }

    return (
        <Accordion elevation={0} expanded={ourStoryExpanded} onChange={ourStoryExpandedChange}>
            <StyledAccordionSummary style={{paddingLeft: 0, height: '25px'}} sx={props.centerSummary?{
                '.MuiAccordionSummary-content': {
                    'justify-content': 'center'
                }
            }:{}}>
                {!ourStoryExpanded &&
                    <AddIcon color='primary'/>
                }
                {ourStoryExpanded &&
                    <RemoveIcon color='primary'/>
                }
                <Typography fontSize={17} color='primary'>Our Story</Typography>
            </StyledAccordionSummary>
            <AccordionDetails  style={{paddingLeft: 10}}>
                <Typography>
                    Hi there, 
                </Typography>
                <Typography paddingTop={0.75}>
                    I started working on Highlightly at university when faced with the inevitable mound of reading to complete and struggling with my Dyslexia. Since leaving university I have built out a UI for the tool and been improving the algorithm - and I’m now on a mission to help businesses and students to work more efficiently and spend less time hunting for the important information.
                </Typography>
                <Typography paddingTop={0.75}>
                    We are only at beginning of our journey if you have any ideas, suggestions or questions about Highlightly please send me a message using the get in touch button.
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default OurStoryAccordion