import { Button, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import Header from '../components/NavBar/NavBar'
import appPreview from "../graphics/appPreview.png"
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react"

const TermsOfServicePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Highlightly | Terms of Service"
    }, [])
    const theme = useTheme();

    return (
        <div>
            <Header showGoToTool={false} showAccountInfo={true}  signOutRedirect={null} showSignUpToNewsletter={false} setShowSignUpToNewsletter={() => {}}/>
            <div style={{padding: 10}}>
            <Typography  variant="h4" align='center'>
                Terms of Service
            </Typography>
            <Typography>
                Welcome to our website, products and services. If you continue to browse or use this website or any of our products or services, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our <Link to={'/privacyNotice'}>privacy policy</Link> govern Highlightly’s relationship with you in relation to this website, our products, and our services. Please read these carefully and contact us at <a href="mailto:highlightlyapplication@gmail.com">highlightlyapplication@gmail.com</a>if you have any questions before using our site, software, or services. If you disagree with or do not understand any part of these terms and conditions or our <Link to={'/privacyNotice'}>privacy policy</Link>, are not authorised to use our site, software or any of our products or services and must leave the site, our products and services immediately.
                <br/>
                <br/>
                The term 'Highlightly' or 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website.
                <br/>
                <br/>
                By continuing to use our site, software, or services you agree to be bound by this agreement and our <Link to={'/privacyNotice'}>privacy policy</Link> and agree that:
                <ul>
                    <li>You are at least 18 years old and if you are entering into this agreement on behalf of an organisation you are authorised by said organisation to do so on their behalf and are doing so on their behalf where you and they are jointly responsible.</li>
                    <li>You are a current resident of the UK, United States of America, or Canada – due to the complexities of individual countries data privacy laws we are currently limiting our products and services user base whilst we continue to work to ensure we are compliant with regulations in other countries.</li>
                    <li>The content of the pages of this website are for your general information use only. It are subject to change without notice.</li>
                    <li>Neither we nor any third parties provide any warranty or guarantee, express or implied, regarding the accuracy, timeliness, performance, completeness, suitability, or availability with respect to the website, information, products, services for any purpose. You accept that such information and materials may contain inaccuracies or errors and we are expressly exclude us from liability for any inaccuracies or errors to the fullest extent permitted by law.</li>
                    <li>Your use of any information, features or materials on our websites, software and services is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</li>
                    <li>In no event will we be liable to you or any third party for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data, profits, sales or business, or any other loss arising from, or in connection with, the use of this website, our products or services regardless of whether you have been advised of the possibility of or we could have foreseen such damages.</li>
                    <li>You agree to the use, storage, and processing of your personal information in line with our <Link to={'/privacyNotice'}>privacy policy</Link> which forms part of this agreement.</li>
                    <li>Your acceptance of these terms of service constitutes your additional agreement to our <Link to={'/privacyNotice'}>privacy policy</Link>.</li>
                    <li>You agree that any and all feedback and suggestions to us and using our software and services is done so voluntarily and accept doing so will grant you no right to any benefits or right to intellectual property generated from these.</li>
                    <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, algorithms, and graphics. Reproduction is prohibited. And you may not copy, create, distribute, publish, or transfer any of our intellectual property.</li>
                    <li>That you will not access or use any of our software or services for the benefit of any other entity.</li>
                    <li>You will not transfer your rights under this agreement to any other entity.</li>
                    <li>You will not attempt to or successfully access any parts of this site or our software or services that you are not allowed to do so or attempt to or to bypass any of our security procedures.</li>
                    <li>You will not attempt to disrupt or interfere with our site, software, or services.</li>
                    <li>You acknowledge that unauthorised use of this website, our software or services may give rise to a claim for damages and/or be a criminal offence.</li>
                    <li>You acknowledge that from time to time this website, our software or services may include links to other websites and that we have no control over the nature, content, and availability of those sites. These are provided for your convenience, and they do not signify that we endorse the website(s), products or services. You agree that we are not responsible for the content or availability of the linked website(s), products or services or your use of these and that you do so at entirely your own risk.</li>
                    <li>You agree that we retain the right to update, remove or limit any parts of, content of and features of our site, software and services at any time at our sole discretion and without any liability to you or any third party.</li>
                    <li>You agree that we may send you emails regarding customer service queries, technical notifications regarding our site, software and services and your use of these </li>
                    <li>You agree that if you sign up to receive marketing emails from us we may send these to you.</li>
                    <li>You agree that you will not transmit any content to our site, software or services that infringes on the legal rights of any entity or for which you do not have the right to upload and provide a licence to (as required by these terms of service).</li>
                    <li>You agree that you will indemnify us, our parents, subsidiaries, directors and shareholders from any losses and liabilities caused by any claims made by any third party arising from your use of our site, software and services and you waive any statue or doctrine that would render this term invalid, unenforceable, or non-legal.</li>
                    <li>You agree that you will not hold us liable for any losses that you face in relation to your use of our site, software, and services for any losses that you face including but not limited to profits, data, or failure to meet your obligations as far is permissible by law.</li>
                    <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland, and Wales.</li>
                    <li>You acknowledge and agree that from time to time we may update this agreement; we will notify you by email and/or by posting the updated terms and conditions or privacy policy to our site. Your use of our products or site following this confirms your agreement to the updated agreement or policy and you agree that it is your responsibility to review these periodically.</li>
                    <li>You agree that if any part of this agreement is found to be non-legal, invalid or un-enforceable by a reasonable court the remaining parts of this agreement will remain in force and that the invalid, non-legal or non-enforceable portions will be replaced by new clauses as close to their intent as allowed by law.</li>
                    <li>You agree that we have the right to suspend or terminate your use of our site, software, products, and services if:
                        <ul>
                            <li>You breach any of these terms</li>
                            <li>Your use of the service could cause risk of harm or loss to us or others</li>
                        </ul>
                    </li>
                    <li>You agree that you may not bring claims against us as part of a plaintiff or class member in any representative action without the agreement of us.</li>
                    <li>We grant you a personal, non-exclusive, non-sublicensable, non-transferable and revocable licence to access and use our site, software and services.</li>
                </ul>
                You are also subject to the additional following conditions regarding user content:
                <br/>
                User content consists of all text, documents, and other content, uploaded, or transmitted by you to our software and services.
                <br/>
                You agree to grant a worldwide, non-exclusive, royalty-free license to your user content for as long as required by intellectual property law for the purposes of:
                <ul>
                    <li>Operating and improving our site, software, and services</li>
                    <li>Protecting our site, software, and services</li>
                    <li>Customizing our site, software, and services</li>
                    <li>Developing new technologies, products, and services</li>
                </ul>
                You agree that we may store, publish, reproduce, modify, and create derivative works of this user content for the purposes above. Additionally, you agree that our service providers may process your user content for these purposes.
                <br/>
                <br/>
                We believe that most problems and disputes can be resolved amicably via communication with us using our email address <a href="mailto:highlightlyapplication@gmail.com">highlightlyapplication@gmail.com</a>.
            </Typography>
            </div>
        </div>
    );
}

export default TermsOfServicePage;
