import { Button, Grid, Typography, styled, Card, CardContent, CardMedia, Stack, SvgIcon, Avatar, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, SwipeableDrawer } from '@mui/material';
import React, { useState } from 'react';
import Header from '../components/NavBar/NavBar';
import appPreview from "../graphics/appPreview.png"
import avalibleInChromeWebStore from "../graphics/avalibleInChomeWebStore.png"

import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react"
import { Box } from '@mui/system';
import CookiesDialog from '../components/CookiesDialog/CookiesDialog';
import HighlightTypeSelector from '../components/HighlightTypeSelectorTile/HighlightTypeSelector';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SwipeableViews from 'react-swipeable-views';
import OurStoryAccordion from '../components/OurStoryAccordion/OurStoryAccordion';
import { AddBox } from '@mui/icons-material';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
      </div>
    );
  }

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Highlightly | Home"
    }, [])
    const navigate = useNavigate();

    const StyledButton = styled(Button)`
    background-color: ${props => props.theme.palette.primary.light};
    color: ${props => props.theme.palette.primary.main};
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
    &:hover {
        background-color: ${props => props.theme.palette.primary.light};
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
    }
    `

    const StyledBox = styled(Box)`
    &:hover {
        text-decoration: underline
    }`

    const theme = useTheme();
    let smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [showSignUpToNewsletter, setShowSignUpToNewsletter] = useState(true)

    const goToTool = () => {
        navigate('/tool')
    }

    const goToChromeExtension = () => {
        window.open("https://chrome.google.com/webstore/detail/highlightly/mflfefkhjbhblmfkbofdpoopmicmmakn?authuser=1")
    }

    const goToAppStore = () => {
        window.open("https://apps.apple.com/gb/app/highlightly/id6448940688")
    }

    const [tabIndex, setTabIndex] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangeTabIndex = (index: number) => {
    setTabIndex(index);
  };

    return (
        <div>
            <Header showGoToTool={true} showAccountInfo={false} signOutRedirect={null} showSignUpToNewsletter={showSignUpToNewsletter} setShowSignUpToNewsletter={setShowSignUpToNewsletter}/>
            <Grid container spacing={4} padding={3}> 
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Typography variant='h3' style={{textAlign: "center"}}>Redefining Reading For Individuals And Teams</Typography>
                        <Typography fontSize={20} style={{textAlign: "center"}} paddingRight={'5rem'} paddingLeft={'5rem'}>Using Highlightly saves you time by surfacing the important insights that get missed</Typography>
                    </Box>

                    <Box paddingTop={3}>
                        <Typography  fontSize={17}  color="primary">See Highlightly In Action</Typography>
                        <div
                            className="video"
                            style={{
                                position: 'relative',
                                paddingBottom: "56.25%", /* 16:9 */
                                height: 0
                            }}
                        >
                            <iframe style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}} src="https://www.youtube.com/embed/iD5qRTx7jU8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Box>

                    <Box paddingTop={3}>
                        <Button style={{height: '50px'}} onClick={goToTool} fullWidth={smallScreen?true:false}>
                            <img src="/highlightlyIcon512.png" style={{height: '42px'}}/>
                            <Typography paddingLeft={1}>Try Highlightly now for Free</Typography>
                        </Button>
                        {smallScreen &&<Box paddingTop={2}/>}
                        <Button style={{height: '50px'}} onClick={goToAppStore} fullWidth={smallScreen?true:false}>
                            <img src="/ios.svg" style={{height: '42px'}}/>
                        </Button>
                        <Button style={{height: '50px'}} onClick={goToChromeExtension} fullWidth={smallScreen?true:false}>
                            <img src="/avalibleInChomeWebStore.png" style={{height: '50px'}}/>
                        </Button>
                    </Box>

                    {!smallScreen &&
                        <Box paddingTop={3}>
                            <OurStoryAccordion/>
                        </Box>
                    }
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChangeTab}
                    >
                        <Tab label='Businesses'/>
                        <Tab label='individuals & Students'/>
                    </Tabs>


                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tabIndex}
                        onChangeIndex={handleChangeTabIndex}
                    >
                        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                            <Grid container spacing={1} padding={1}> 
                                <Grid item xs={12} sm={6} padding={4}>
                                    <Stack>
                                        <img src="/homePageGraphics/ideas.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>save your team time sifting through documents for key insights</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} padding={4}>
                                    <Stack>
                                        <img src="/homePageGraphics/reading.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>improve your reports by Finding key insights in documents </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} padding={4}>
                                    <Stack>
                                        <img src="/homePageGraphics/todo.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>give your clients insights sooner by using highlightly</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} padding={smallScreen?0:4}>
                                    <Stack>
                                        <img src="/homePageGraphics/basketball.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>Helps your team to reach their full potential</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                            <Grid container spacing={1} padding={1}> 
                                <Grid item xs={12} sm={6} padding={4}>
                                    <Stack>
                                        <img src="/homePageGraphics/reading.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>Helps you by highlighting the key insights in your readings</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} padding={4}>
                                    <Stack>
                                        <img src="/homePageGraphics/ideas.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>improve your reports by finding that critical missing stat or quote</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} padding={4}>
                                    <Stack>
                                        <img src="/homePageGraphics/basketball.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>Get back to what you want to me </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} padding={smallScreen?0:4}>
                                    <Stack>
                                        <img src="/homePageGraphics/todo.svg" style={{maxHeight: 275}} />
                                        <Typography textAlign={'center'} variant='overline' paddingLeft={2} paddingRight={2}>Blast through your reading list like a superstar</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </SwipeableViews>
                </Grid>

                {smallScreen &&
                    <Grid item xs={12} sm={6} paddingBottom={3}>
                        <OurStoryAccordion centerSummary/>
                    </Grid>
                }
            </Grid>

            
        </div>
    );
}

export default HomePage;
