import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles'
import { Box, Button, Collapse, Dialog, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Switch, ToggleButton, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';

interface CookiesDialogProps {
    showDialog: boolean
    setShowDialog: (value: boolean) => void
    setAnalyticsAllowed: (value: boolean) => void
}

const CookiesDialog = (props: CookiesDialogProps) => {
    const [cookies, setCookie, removeCookie] = useCookies(['highlightly-preferences'])
    const [showCustomSettings, setShowCustomSettings] = useState(false)
    const [allowAnalyticalCookies, setAllowAnalyticalCookies] = useState(false)

    const handleAllowAllCookies = () => {
        changeCookiePreferences(true, 7890000)
        props.setShowDialog(false)
    }

    const handleRejectNonEssentialCookies = () => {
        changeCookiePreferences(false, 604800)
        props.setShowDialog(false)
    }

    const handleSaveCustomSettings = () => {
        changeCookiePreferences(allowAnalyticalCookies, 604800)
        props.setShowDialog(false)
    }

    const changeCookiePreferences = (analyticCookiesAllowed: boolean, secondsUntilNextAsk: number) => {
        setCookie("highlightly-preferences", {"necassary": true, "analytical": analyticCookiesAllowed}, {path: '/', maxAge: secondsUntilNextAsk});
        props.setAnalyticsAllowed(analyticCookiesAllowed)
    }

    const handleAnalyticsAllowedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowAnalyticalCookies(event.target.checked);
    }

    return(
        <Dialog open={props.showDialog}>
            <DialogTitle color='primary'>Cookies</DialogTitle>
            <Typography variant='caption' marginRight={'24px'} marginLeft={'24px'}>We use necessary cookies to enable our site and additionaly would like to set analytics cookies to understand how our site is used and monitor it. Please see our privacy policy linked in the footer to understand more fully how we use cookies.</Typography>
            <Grid container spacing={4} padding={'24px'}> 
                <Grid item sm={6} xs={12}>
                    <Button style={{height:'4em'}} variant='outlined' fullWidth onClick={handleAllowAllCookies}>Allow all cookies</Button>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Button style={{height:'4em'}} variant='outlined' fullWidth onClick={handleRejectNonEssentialCookies}>Reject non esential cookies</Button>
                </Grid>
            </Grid>
            <Button variant='text' onClick={() => {setShowCustomSettings(!showCustomSettings)}}>Customise Settings</Button>
            <Divider/>
            <Collapse in={showCustomSettings}>
                <Box padding={'24px'}>
                    <Typography>Necessary Cookies</Typography>
                    <Typography variant='caption'>These are cookies that are strictly necissary to make our site work, for example to enable authentication and store your cookie preferences</Typography>

                    <Box paddingTop={'12px'} paddingBottom={'12px'}>
                        <Divider/>
                    </Box>
                    
                    <Grid container>
                        <Grid item sm={9} xs={12}>
                            <Typography>Analytical Cookies</Typography>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch checked={allowAnalyticalCookies} onChange={handleAnalyticsAllowedChanged}/>
                                    }
                                    label={<Typography variant='caption'>Allow</Typography>}
                                />
                            </FormGroup>
                        </Grid>
                        <Typography variant='caption'>We use analytical cookies to improve our website by understanding how our users use it. The information from these cookies is shared with Google Analytics, please visit our privacy policy for more infroamtion about how we protect your privacy.</Typography>
                    </Grid>

                    <Box paddingTop={'12px'} paddingBottom={'12px'}>
                        <Divider/>
                    </Box>

                    <Grid container>
                        <Button variant='outlined' onClick={handleSaveCustomSettings}>{"Save & Close"}</Button>
                    </Grid>
                </Box>
            </Collapse>
        </Dialog>
    );
}

export default CookiesDialog