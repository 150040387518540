import { User } from "firebase/auth";
import SummarisedReturn from "../models/SummarisedReturn";
import React, { Component } from "react";
import rp from "request-promise";
import cheerio from "cheerio";
import { AxiosResponse } from "axios";

const axios = require('axios')

declare global {
    interface Window { API_URL: string; }
}

let url:string|undefined;
console.log("URL")
if (process.env.REACT_APP_API_URL != "") {
    console.log(process.env.REACT_APP_API_URL)
    url = process.env.REACT_APP_API_URL
}
else {
    url = window.API_URL
}
console.log(url)

console.log("url: ")
console.log(url)

let PageScraper = {
    getContent: (contentUrl: string): Promise<AxiosResponse> => {
        return axios.post(url+'/content/', {
            contentUrl
        },
        )
    },
}

export default PageScraper