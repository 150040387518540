import React, {useContext, useState} from 'react';
import EntryForm from '../components/EntryForm/EntryForm';
import HighlightedOutputViewer from '../components/HighlightedTextOutputViewer/HighlightedTextOutputViewer';
import Header from '../components/NavBar/NavBar';
import InputProcessor from "../services/InputProcessor"
import SummarisedReturn from '../models/SummarisedReturn';
import{Alert, AlertTitle, Box, Button, CircularProgress, Divider, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material/';
import { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import { UserContext } from '../UserContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import axios, { AxiosError } from 'axios';
import HighlightTypeSelector from '../components/HighlightTypeSelectorTile/HighlightTypeSelector';
import $ from 'jquery';
import HighlightTypeModal from '../components/HighlightTypeModal/HighlightTypeModal';
import PageScraper from '../services/PageScraper';

const Tool = () => {
    const user = useContext(UserContext)

    const { content } = useParams();
    const navigate = useNavigate();
    const auth = getAuth();
    let [searchParams, setSearchParams] = useSearchParams();
    const [loadingContent, setLoadingContent] = useState(false);
    const [showContentLoadError, setShowContentLoadError] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Highlightly | Tool"
        if (content != undefined) {
            setNewValue(content)
        }
        const searchURL = searchParams.get('url')
        setShowContentLoadError(false)
        if (searchURL != undefined) {
            setLoadingContent(true)
            PageScraper.getContent(searchURL).then((response) => {
                setNewValue(response.data)
                setLoadingContent(false)
            })
            .catch((error) => {
                setLoadingContent(false)
                setShowContentLoadError(true)
            }) 
        }

        // if (user == null) {
        //     var destinationPath = 'destination=' + window.location.pathname
        //     navigate({pathname: '/signin', search: destinationPath})
        // }
        // else if (user.emailVerified == false) {
        //     var destinationPath = 'destination=' + window.location.pathname
        //     navigate({pathname: '/signin', search: destinationPath})
        // }
    }, [])

    const [inputValue, setInputValue] = useState('');
    const [inputValueValid, setInputValueValid] = useState(false);
    const [inputValueHelperText, setinputValueHelperText] = useState("Please enter a value to analyse");
    const[outputText, setOutputText] = useState<SummarisedReturn|undefined>(undefined);
    const[resultLoading, setResultLoading] = useState(false)
    const[showLoginAgainError, setShowLoginAgainError] = useState(false)
    const[showError, setShowError] = useState(false)
    const[errorMessage, setErrorMessage] = useState("")
    const[anyKey, setAnyKey] = useState(false)

    const [smartHighlightSelected, setSmartHighlightSelected] = useState(true)
    const [smartHighlightHovering, setSmartHighlightHovering] = useState(false)
    const [smartHighlightCount, setSmartHighlightCount] = useState(0)

    const [statsSelected, setStatsSelected] = useState(false)
    const [statsHovering, setStatsHovering] = useState(false)
    const [keyStatsCount, setKeyStatsCount] = useState(0)

    const [storySelected, setStorySelected] = useState(false)
    const [storyHovering, setStoryHovering] = useState(false)
    const [storyCount, setStoryCount] = useState(0)

    const [dateTimeSelected, setDateTimeSelected] = useState(false)
    const [dateTimeHovering, setDateTimeHovering] = useState(false)
    const [dateTimeCount, setDateTimeCount] = useState(0)

    const [namesSelected, setNamesSelected] = useState(false)
    const [namesHovering, setNamesHovering] = useState(false)
    const [namesCount, setNamesCount] = useState(0)

    
    const handleInputValueChange = (event:any) => {
        setNewValue(event.target.value);
    };

    const setNewValue = (newValue: string) => {
        setShowLoginAgainError(false)
        setShowError(false)
        setInputValue(newValue);

        if (newValue.length > 0) {
            setInputValueValid(true)
            setinputValueHelperText("")
        }
        else {
            setInputValueValid(false)
            setinputValueHelperText("Please enter a value to analyse")
        }

        let length = newValue.split(/\s+/).length
        console.log(length)
        if (length > 5000) {
            setInputValueValid(false)
            setinputValueHelperText("Sorry we are only able to analyse up to 5000 words at the moment (" + length + " words of 5000)")
        }
        setOutputText(undefined)
    }
    
    const handleSubmission = () => {
        // if (user == null) {
        //     setShowLoginAgainError(true)
        // }
        // else {
            setOutputText(undefined);
            console.log("got here with input: " + inputValue)
            setResultLoading(true);
            // InputProcessor.getProcessedInput(inputValue, requestType, user).then((output) => {
            InputProcessor.getProcessedInput(inputValue).then((output) => {
                setShowLoginAgainError(false)
                setShowError(false)
                console.log("got output text");
                console.log(output);
                console.log(output.data);
                setOutputText(output.data)
                setResultLoading(false);

                setAnyKey(false)
                let smartHighlightLocalCount = 0
                let dateTimeLocalCount = 0
                let namesLocalCount = 0
                let storyLocalCount = 0
                let keyStatsLocalCount = 0

                if (output.data != undefined && output.data.summarisedText != undefined) {
                    for (let i = 0; i < output.data.summarisedText.length; i++) {
                        let item = output.data.summarisedText[i]
                        if (item.contains.authorsMessage || item.contains.date || item.contains.name || item.contains.people || item.contains.stats ) {
                            setAnyKey(true)
                            if (item.contains.authorsMessage) {
                                smartHighlightLocalCount++
                            }
                            if (item.contains.date) {
                                dateTimeLocalCount++
                            }
                            if (item.contains.name) {
                                namesLocalCount++
                            }
                            if (item.contains.people) {
                               storyLocalCount++
                            }
                            if (item.contains.stats) {
                                keyStatsLocalCount++
                            }
                        }
                    }
                }

                setSmartHighlightCount(smartHighlightLocalCount)
                setDateTimeCount(dateTimeLocalCount)
                setNamesCount(namesLocalCount)
                setStoryCount(storyLocalCount)
                setKeyStatsCount(keyStatsLocalCount)
                
            }).catch((error: Error | AxiosError) => {
                setOutputText(undefined)
                setResultLoading(false)

                if (axios.isAxiosError(error)) {
                    console.log(error)
                    if (error.response) {
                        if (error.response.status == 401) {
                            setShowError(false)
                            setShowLoginAgainError(true)
                        }
                        else {
                            setShowLoginAgainError(false)
                            setErrorMessage(error.response.status + ": " + error.response.data.message)
                            setShowError(true)
                        }
                    }
                    else {
                        setShowLoginAgainError(false)
                        setErrorMessage(error.message)
                        setShowError(true)
                    }
                    
                }
                else {
                    setShowLoginAgainError(false)
                    setErrorMessage("Internal server error occured, please contact us for support if this percists")
                    setShowError(true)
                }
                
                // TODO show an error message of some kind
            })
        // }
    }

    const handleSignBackIn = () => {
        navigate('/signin')
    }

    const [minHeight, setMinHeight] = useState(0)

    useEffect(() => {
        // make sure that at a minimum we are filling the screen 
        let outputBoxTopOffset = document?.getElementById('outputBox')?.offsetTop
        if (outputBoxTopOffset != undefined) {
            // 25 is the footer at the bottom of the page
            setMinHeight(window.innerHeight - outputBoxTopOffset - 25)
        }
        else {
            setMinHeight(0)
        }
    }, [])

    const [sidebarTop, setSidebarTop] = useState(0)
    const onScroll = () => {
        let headerHeight = document.getElementById('header')?.clientHeight || 0
        setSidebarTop(headerHeight - window.scrollY > 0 ? headerHeight - window.scrollY: 0)
    }

    useEffect(() => {
        onScroll()
        window.addEventListener('scroll', onScroll)
    }, [])

    const [showSignUpToNewsletter, setShowSignUpToNewsletter] = useState(true)

    useEffect(() => {
        //if the sign up to newsletter is closed then the headersize changes so recalculate top distance for sidebar content
        onScroll()
    }, [showSignUpToNewsletter])

    const [inputOutputPaneWidth, setInputOutputPaneWidth] = useState(0)

    const handleResize = () => {
        setInputOutputPaneWidth(window.innerWidth - 248);
    }

    // useEffect(() => {
    //     //if window orientation changes we need to change width of the tools section
    //     window.addEventListener("orientationchange", handleResize)
    //     return () => window.removeEventListener("orientationchange", handleResize)
    // }, [])

    useEffect(() => {
        //as the window resizes we need to change the width of the tools section
        window.addEventListener("resize", handleResize);
        //set initial value for the width
        handleResize()

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const theme = useTheme();
    let smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Header showGoToTool={false} showAccountInfo={true}  signOutRedirect='/signin' showSignUpToNewsletter={showSignUpToNewsletter} setShowSignUpToNewsletter={setShowSignUpToNewsletter}/>
            <Box id='outputBox' display="flex" sx={smallScreen?{}:{'width': inputOutputPaneWidth, 'minHeight': minHeight}}>
                <Box flexGrow={1}>
                    {showLoginAgainError &&
                    <Alert severity="error">
                        <AlertTitle>Login timed out</AlertTitle>
                        <Button variant='contained' color='error' onClick={handleSignBackIn}>Sign back in</Button>
                    </Alert>
                    }
                    {showContentLoadError &&
                    <Alert severity="error">
                        <AlertTitle>Content of site could not be automatically copied</AlertTitle>
                        <Typography>Please try again, if you continue to see this error please get in touch.</Typography>
                    </Alert>
                    }
                    {loadingContent &&
                        <div style={{"textAlign":"center"}}>
                            <CircularProgress color="secondary"/>
                        </div>
                    }
                    {!loadingContent && 
                    <EntryForm inputValue={inputValue} 
                        handleInputValueChange={handleInputValueChange} 
                        isInputValid={inputValueValid}
                        helperText={inputValueHelperText}
                        handleSubmission={handleSubmission}
                        resultLoading={resultLoading}
                        showError={showError}
                        errorMessage={errorMessage}/>
                    }
                    {smallScreen &&
                    <div>
                        <Box display='flex' alignItems={'center'} paddingLeft={'10px'} paddingRight={'10px'}>
                            <Typography variant='h6' fontSize={'1.125rem'}>Highlight:</Typography>
                            <div style={{marginLeft: 'auto'}}>
                                <HighlightTypeModal/>
                            </div>
                        </Box>
                        <Box sx={{display: 'flex', flexWrap: 'nowrap', width: window.innerWidth, overflowY: 'auto'}}>
                            <div style={{padding: 4}}>
                                <HighlightTypeSelector colorCode={'#E3EFFE'} title='Smart Highlight' appearances={smartHighlightCount} isSelected={smartHighlightSelected} setIsSelected={setSmartHighlightSelected} isHovering={smartHighlightHovering} setIsHovering={setSmartHighlightHovering}/>
                            </div>
                            <div style={{padding: 4}}>
                                <HighlightTypeSelector colorCode={'#EAFEE4'} title='Key Stats' appearances={keyStatsCount} isSelected={statsSelected} setIsSelected={setStatsSelected} isHovering={statsHovering} setIsHovering={setStatsHovering}/>
                            </div>
                            <div style={{padding: 4}}>
                                <HighlightTypeSelector colorCode={'#FDF0E1'} title='The Story' appearances={storyCount} isSelected={storySelected} setIsSelected={setStorySelected} isHovering={storyHovering} setIsHovering={setStoryHovering}/>
                            </div>
                            <div style={{padding: 4}}>
                                <HighlightTypeSelector colorCode={'#D9D9FD'} title='Date and Times' appearances={dateTimeCount} isSelected={dateTimeSelected} setIsSelected={setDateTimeSelected} isHovering={dateTimeHovering} setIsHovering={setDateTimeHovering}/>
                            </div>
                            <div style={{padding: 4}}>
                                <HighlightTypeSelector colorCode={'#FCE1DE'} title='Names' appearances={namesCount} isSelected={namesSelected} setIsSelected={setNamesSelected} isHovering={namesHovering} setIsHovering={setNamesHovering}/>
                            </div>
                        </Box>
                    </div>
                    }
                    

                    {/* <TableOutputViewer outputText={outputText}/> */}
            
                    <HighlightedOutputViewer outputText={outputText} namesSelected={namesSelected} namesHovering={namesHovering} dateTimeSelected={dateTimeSelected} dateTimeHovering={dateTimeHovering} storySelected={storySelected} storyHovering={storyHovering} statsSelected={statsSelected} statsHovering={statsHovering} smartHighlightSelected={smartHighlightSelected} smartHighlightHovering={smartHighlightHovering} anyKey={anyKey}/>
                </Box>
                {!smallScreen &&
                <Divider orientation="vertical" variant="middle" flexItem />
                }
                {!smallScreen &&
                <Box flexGrow={0} height='100%' width='248px' position='fixed' right={0} top={sidebarTop}>
                        <Stack id="highlightTypeSelectors" spacing={1} style={{'float': 'right'}} padding={2}>
                            <Box display='flex' alignItems={'center'}>
                                <Typography variant='h6'>Highlight:</Typography>
                                <div style={{marginLeft: 'auto'}}>
                                    <HighlightTypeModal/>
                                </div>
                            </Box>
                            <HighlightTypeSelector colorCode={'#E3EFFE'} title='Smart Highlight' appearances={smartHighlightCount} isSelected={smartHighlightSelected} setIsSelected={setSmartHighlightSelected} isHovering={smartHighlightHovering} setIsHovering={setSmartHighlightHovering}/>
                            <HighlightTypeSelector colorCode={'#EAFEE4'} title='Key Stats' appearances={keyStatsCount} isSelected={statsSelected} setIsSelected={setStatsSelected} isHovering={statsHovering} setIsHovering={setStatsHovering}/>
                            <HighlightTypeSelector colorCode={'#FDF0E1'} title='The Story' appearances={storyCount} isSelected={storySelected} setIsSelected={setStorySelected} isHovering={storyHovering} setIsHovering={setStoryHovering}/>
                            <HighlightTypeSelector colorCode={'#D9D9FD'} title='Date and Times' appearances={dateTimeCount} isSelected={dateTimeSelected} setIsSelected={setDateTimeSelected} isHovering={dateTimeHovering} setIsHovering={setDateTimeHovering}/>
                            <HighlightTypeSelector colorCode={'#FCE1DE'} title='Names' appearances={namesCount} isSelected={namesSelected} setIsSelected={setNamesSelected} isHovering={namesHovering} setIsHovering={setNamesHovering}/>
                        </Stack>
                </Box>
                }
            </Box>
        </div>
    );
}

export default Tool;
