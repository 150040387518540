import { forwardRef, ReactElement, Ref, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, IconButton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightTypeDescription from './HighlightTypeDescription';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HighlightTypeModal = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <InfoOutlinedIcon/>
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
            {"Highlight Types"}
            <IconButton onClick={handleClose} style={{float: 'right'}}>
                <CloseOutlinedIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <HighlightTypeDescription colorCode={'#E3EFFE'} title={'Smart Highlight'} description={'This is our headline setting and uses Natural language processing (yeah techi sounding I know) to cut the waffle and tell you what the author wants to covey in fewer sentences.'}/>
            <HighlightTypeDescription colorCode={'#EAFEE4'} title='Key Stats' description={'Trying to find some stats for your paper or find that pesky one that got away then this is the setting for you it\’ll find all the sentences with stats in them and highlight them.'}/>
            <HighlightTypeDescription colorCode={'#FDF0E1'} title='The Story' description={'This option highlights all the sentences about people and places and tells the story within the document or article but leaves out the extra anecdotes.'}/>
            <HighlightTypeDescription colorCode={'#D9D9FD'} title='Date and Times' description={'Reading a history paper or a email and need to quickly find all the dates and times, well look no further than these highlights they are the ones your looking for.'}/>
            <HighlightTypeDescription colorCode={'#FCE1DE'} title='Names' description={'Forgotten a key name or need to find all the named people in a document try out this option and the sentences containing names will be highlighted.'}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HighlightTypeModal
