import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import {  getAuth, User } from 'firebase/auth'

export const UserContext = createContext<User|null>(null)

const UserProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [user, setUser] = useState<User|null>(null)

    useEffect(() => {
        getAuth().onAuthStateChanged(setUser);
      }, []);

    return (
        <UserContext.Provider value={user}>
                {children}
        </UserContext.Provider>
    )
}

export default UserProvider