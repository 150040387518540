import { User } from "firebase/auth";
import { config } from "process";
import SummarisedReturn from "../models/SummarisedReturn";

const axios = require('axios')

declare global {
    interface Window { API_URL: string; }
}

let url:string|undefined;
if (process.env.REACT_APP_API_URL != "") {
    url = process.env.REACT_APP_API_URL
}
else {
    url = window.API_URL
}


let InputProccessor = {
    // getProcessedInput: async (input: string, requestType: string, user: User): Promise<{data: SummarisedReturn}> => {
    getProcessedInput: async (input: string): Promise<{data: SummarisedReturn}> => {
        let request = input;
        console.log("making request to: " + url+'/summarise')
        // const token = await user.getIdToken()
        return axios.post(url+'/summarise/', {
            request
        },
        // {headers: { Authorization: `Bearer ${token}` }}
        )
    },
}

export default InputProccessor