import { Button, Typography } from "@mui/material";
import React, { Component, useState } from 'react';

interface ContactMeButtonProps {

}

const ContactMeButton = (props: ContactMeButtonProps) => {
    const Emoji = (props: { label: string; symbol: string; fontSize?: string }) => {
        return (
            <span
                className="emoji"
                role="img"
                aria-label={props.label ? props.label : ""}
                aria-hidden={props.label ? "false" : "true"}
            >
                <Typography fontSize={props.fontSize}>
                    {props.symbol}
                </Typography>
            </span>
        );
    }

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [increaseWidth, setIncreaseWidth] = useState(false);
    const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);

    const timeoutMilliSeconds = 300

    const handleClick = (e:any) => {
        e.preventDefault(); 
        if (showConfirmation) {
            navigator.clipboard.writeText('highlightlyapplication@gmail.com')
            setIncreaseWidth(false);
            setShowCopyConfirmation(true);
        }
        else {
            setIncreaseWidth(true)
            window.location.href='mailto:highlightlyapplication@gmail.com?subject=Hello&body=Hi Edward,';
            setTimeout(() => {
                setShowConfirmation(true)
            }, timeoutMilliSeconds)

            setTimeout(() => {
                setIncreaseWidth(false);
                setShowConfirmation(false);
                setShowCopyConfirmation(false);
            }, 10000);
        }
    }

    

    return(
        <Button variant="contained" onClick={handleClick} style={{borderRadius: '40px', position: 'fixed', bottom: 30, right: 20, marginLeft: 20, transition: 'width .3s', width: increaseWidth?window.innerWidth-40:'211px', WebkitTransition: 'width .3s', transform: `translate(2px, 2px)` }}>
            {!showConfirmation &&
            <>
            <Emoji symbol="👋" label="waving heand" fontSize={'1.5rem'}/> <Typography fontSize={'1.2rem'} color={'#FFFFFF'}>&nbsp;Get in touch</Typography>
            </>
            }
            {showConfirmation && !showCopyConfirmation &&
            <>
            <Typography variant="body1" color={'#FFFFFF'}>Your email program is opening, click again now to copy our address: highlightlyapplication@gmail.com to enter in web browser</Typography>
            </>
            }
            {showConfirmation && showCopyConfirmation &&
            <>
            <Typography variant="body1" color={'#FFFFFF'}>Copied</Typography>
            </>
            }

        </Button>
    );
}

export default ContactMeButton