import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles'

interface EntryFieldProps {
    inputValue: string
    handleInputValueChange: (event: any) => any
    isInputValid: boolean
}

const EntryField = (props: EntryFieldProps) => {
    return(
        <TextField
            id="entryText"
            label="Copy and paste text here to analyse"
            multiline
            value={props.inputValue}
            onChange={props.handleInputValueChange}
            fullWidth
            color= 'secondary'
            error={((!props.isInputValid))}
        />
    );
}

export default EntryField