import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { Typography, Button, makeStyles, styled, IconButton, Alert, AlertTitle } from '@mui/material';
import {useTheme, withStyles} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import AccountMenu from '../AccountMenu/AccountMenu';
import { useState } from 'react';

interface NavBarProps {
    showGoToTool: boolean
    showAccountInfo: boolean
    signOutRedirect: string|null
    showSignUpToNewsletter: Boolean
    setShowSignUpToNewsletter:  any
}

const NavBar = (props: NavBarProps) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const useStyle = {
          backgroundColor: theme.palette.primary.light,
        //   color: '#fff',
         '&:hover': {
            backgroundColor: theme.palette.primary.main,
            // color: '#3c52b2',
        },
      };

    const StyledButton = styled(Button)`
    background-color: ${props => props.theme.palette.primary.light};
    color: ${props => props.theme.palette.primary.main};
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
    &:hover {
        background-color: #F5FBFF;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
    }
    `
    
    const handleNewsletterSignUp = () => {
        props.setShowSignUpToNewsletter(false)
        navigate('/stayInformed')
    }

    return (
        <div id='header'>
        <div style={{width:"100%", height: "52.5px", backgroundColor:theme.palette.primary.main, textAlign:"center"}}>
            <Grid container height={"100%"}>
                        <Grid item xs={4}>
                            
                        </Grid>
                        <Grid item xs={4} container justifyContent="center" direction="column" display="flex">
                            <Link to={'/'}>
                                <Typography style={{display: "inline-block"}} variant="h5" color="white">
                                    Highlightly
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end" padding={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {props.showGoToTool &&
                                    <StyledButton
                                        variant="contained"
                                        onClick={() => {navigate('/tool')}}
                                        style={{whiteSpace: "nowrap", borderRadius: '11px', height: '30px'}}
                                    >
                                        Go To Tool
                                    </StyledButton>
                                }
                            </div>
                            {/* <div>
                                {props.showAccountInfo &&
                                    <AccountMenu signOutRedirect={props.signOutRedirect}/>
                                }
                            </div> */}
                        </Grid>
                    </Grid>

                    
            
            
        </div>
            {props.showSignUpToNewsletter &&
                <Alert onClose={() => {props.setShowSignUpToNewsletter(false)}} severity="info" style={{marginBottom: 10}}>
                    <AlertTitle>Love what we are doing! Stay up to date, Sign up to our newsletter</AlertTitle>
                    <Button onClick={handleNewsletterSignUp}>Sign up</Button>
                </Alert>
            }
        </div>
    );
}

export default NavBar;