import React from 'react';
import Header from '../components/NavBar/NavBar'

import { useEffect } from "react"
import Helmet from 'react-helmet';
// import Helmet from 'react-helmet'

// function AddLibrary() {
//     const script = document.createElement('script');
//     script.src = "https://hustling-hustler-6348.ck.page/b0c6417442/index.js";
//     script.async = true;
//     document.body.appendChild(script);
//   }  





  // componentDidMount() {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://hustling-hustler-6348.ck.page/b0c6417442/index.js";
  //   script.onload = () => this.scriptLoaded();



  //   //For head
  //   document.head.appendChild(script);

  //   // For body
  //   document.body.appendChild(script);

  //   // For component
  //   this.div.appendChild(script);

  // }

const StayInformedPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Highlightly | Stay Informed"
    const script = document.createElement("script")

    script.src =
      "https://hustling-hustler-6348.ck.page/b0c6417442/index.js"

    script.async = true
    script.setAttribute('data-uid', 'b0c6417442')

    // script.crossOrigin = "anonymous"
    console.log(script)

    document.getElementById('formDiv')?.appendChild(script)
    return () => {
      // clean up the script when the component in unmounted
      document.getElementById('formDiv')?.removeChild(script)
    }
  }, [])

  // return <div className="App"></div>

    return (
        <div>
          <Helmet>
            <title>Highlightly</title>
            <meta name="description" content="Highlightly" />
          </Helmet>
            <Header showGoToTool={true} showAccountInfo={false}  signOutRedirect='/signin' showSignUpToNewsletter={false} setShowSignUpToNewsletter={() => {}}/>
            <div id='formDiv'></div>
        </div>
    );
}

export default StayInformedPage;
