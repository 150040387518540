import { ContentPasteOutlined } from "@mui/icons-material";
import SummarisedReturn from "../../models/SummarisedReturn";
import{Alert, AlertTitle, Typography } from '@mui/material/';
import { useState } from "react";
import { JsxElement } from "typescript";
import * as ReactDOMServer from "react-dom/server"


interface HeighlightedOutputViewerProps {
    outputText: SummarisedReturn|undefined
    namesSelected: Boolean
    namesHovering: Boolean
    dateTimeSelected: Boolean
    dateTimeHovering: Boolean
    storySelected: Boolean
    storyHovering: Boolean
    statsSelected: Boolean
    statsHovering: Boolean
    smartHighlightSelected: Boolean
    smartHighlightHovering: Boolean
    anyKey: Boolean
}

const HighlightedOutputViewer = (props: HeighlightedOutputViewerProps) => {
    if (props.outputText != undefined && props.outputText.summarisedText != undefined) {
        // creat the text objec to be displacyed
        var sentences = props.outputText.summarisedText.map(function(object){
            // each sentence
            console.log(object.key)
            const textArray = object.key.split('\n');
            let i = 0;
            const textWithBreaks = [];
            for (const item of textArray){
                // if not the last item in the group then add a break line jsx element
                textWithBreaks.push(<>{item}{i != textArray.length-1 ? <br/> : null}</>);
                i++;
            }
            
            let spanCount = 0;
            let currentSentence = <>{textWithBreaks}</>
            if (object.contains.authorsMessage == true && (props.smartHighlightSelected || props.smartHighlightHovering)) {
                currentSentence = <span style={{'backgroundColor': 'rgba(0, 150, 255, 0.15)', 'borderColor': 'rgba(0, 150, 255, 0.5)', 'padding': (3 + (spanCount * 2)) + 'px', 'margin': '0 ' + (-3 + (spanCount)) + 'px', 'borderRadius': (5 + (spanCount * 2)) + 'px'}}>{currentSentence}</span>
                spanCount++
            }
            if (object.contains.stats == true && (props.statsSelected || props.statsHovering)) {
                //if the item is key surround it with the mark tag
                currentSentence = <span style={{'backgroundColor': 'rgba(0, 255, 0, 0.15)', 'borderColor': 'rgba(0, 255, 0, 0.5)', 'padding': (3 + (spanCount * 2)) + 'px', 'margin': '0 ' + (-3 + (spanCount)) + 'px', 'borderRadius': (5 + (spanCount * 2)) + 'px'}}>{currentSentence}</span>
                spanCount++
            }
            if (object.contains.people == true && (props.storySelected || props.storyHovering)) {
                //if the item is key surround it with the mark tag
                currentSentence = <span style={{'backgroundColor': 'rgba(255, 150, 0, 0.15)', 'borderColor': 'rgba(255, 150, 0, 0.5)', 'padding': (3 + (spanCount * 2)) + 'px', 'margin': '0 ' + (-3 + (spanCount)) + 'px', 'borderRadius': (5 + (spanCount * 2)) + 'px'}}>{currentSentence}</span>
                spanCount++
            }
            if (object.contains.date == true && (props.dateTimeSelected || props.dateTimeHovering)) {
                //if the item is key surround it with the mark tag
                currentSentence = <span style={{'backgroundColor': 'rgba(0, 0, 255, 0.15)', 'borderColor': 'rgba(0, 0, 255, 0.5)', 'padding': (3 + (spanCount * 2)) + 'px', 'margin': '0 ' + (-3 + (spanCount)) + 'px', 'borderRadius': (5 + (spanCount * 2)) + 'px'}}>{currentSentence}</span>
                spanCount++
            }
            if (object.contains.name == true && (props.namesSelected || props.namesHovering)) {
                //if the item is key surround it with the mark tag
                currentSentence =  <span style={{'backgroundColor': 'rgba(255, 0, 0, 0.15)', 'borderColor': 'rgba(255, 0, 0, 0.5)', 'padding': (3 + (spanCount * 2)) + 'px', 'margin': '0 ' + (-3 + (spanCount)) + 'px', 'borderRadius': (5 + (spanCount * 2)) + 'px'}}>{currentSentence}</span>
                spanCount++
            }

            currentSentence = <>{currentSentence}{' '}</>
            console.log(ReactDOMServer.renderToString(currentSentence))
            return currentSentence;
        })

        return (
            <div>
                { !props.anyKey && props.outputText != undefined && props.outputText.summarisedText != undefined &&
                    <Alert severity="warning" style={{marginBottom: 10}}>
                        <AlertTitle>Drat, sorry your document is too short for us to acurately sumarise at this time</AlertTitle>
                        Please get in touch and let us know, we are always trying to improve our tools!
                    </Alert>
                }
                <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                    <Typography variant="h5">
                        Results
                    </Typography>
                    <Typography style={{paddingLeft:"2px", "lineHeight": "35px"}}> 
                        {sentences}
                    </Typography> 
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default HighlightedOutputViewer