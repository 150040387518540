import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { Typography, Button, makeStyles, styled, Box, useMediaQuery, Stack } from '@mui/material';
import {useTheme, withStyles} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef } from 'react';
interface FooterProps {
    setShowCookieDialog: (value: boolean) => void
}

const Footer = (props: FooterProps) => {
    const theme = useTheme();
    let smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            {!smallScreen &&
            <div style={{backgroundColor:"#EBEBEB", height:'22px', padding: '5px'}}>
                <Box style={{marginLeft: '24px', marginRight: '24px'}}>
                    <Link to={'/termsOfService'}>Terms of Service</Link>
                    {" | "}
                    <Link to={'/privacyNotice'}>Privacy & Cookies Notice</Link>
                    {" | "}
                    <Link to={'/contactUs'}>Contact Us</Link>
                    
                    <a style={{float: 'right', textDecoration: 'underline'}} className='cookieSettingsLink' onClick={() => {props.setShowCookieDialog(true)}}>Change Cookie Settings</a>
                </Box>
            </div>
            }
            {smallScreen &&
            <div style={{backgroundColor:"#EBEBEB", padding: '5px', height: '85px'}}>
                <Box style={{marginLeft: '24px', marginRight: '24px'}}>
                    <Stack spacing={0.5}>
                        <Link to={'/termsOfService'}>Terms of Service</Link>
                        <Link to={'/privacyNotice'}>Privacy & Cookies Notice</Link>
                        <Link to={'/contactUs'}>Contact Us</Link>
                        <a style={{float: 'right', textDecoration: 'underline'}} className='cookieSettingsLink' onClick={() => {props.setShowCookieDialog(true)}}>Change Cookie Settings</a>
                    </Stack>
                </Box>
            </div>
            }
        </div>
    );
}

export default Footer;