import React from 'react';
import { TextField, Button, CircularProgress, Typography, Checkbox, Tooltip, Alert, AlertTitle } from '@mui/material/';
import EntryField from "../EntryField/EntryField"
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import {useState} from 'react';
import RequestTypeSelector from '../RequestTypeSelector/RequestTypeSelector';
import { Link } from 'react-router-dom';

interface EntryFormProps {
    inputValue: string
    handleInputValueChange: (event: any) => any
    isInputValid: boolean
    helperText: string
    handleSubmission: (event: any) => any
    resultLoading: boolean
    showError: boolean
    errorMessage: string
}

const EntryForm = (props: EntryFormProps) => {
    const handleInputValueChange = (event: any) => {
        setUsedForm(true)
        props.handleInputValueChange(event)
    }

    const handleSubmission = (event: any) => {
        setUsedForm(true)
        props.handleSubmission(event)
    }

    const[usedForm, setUsedForm] = useState(false)
    const[toSAndPNAccepted, setToSAndPNAccepted] = useState(false)

     const handleChangeToSAndPN = (event: React.ChangeEvent<HTMLInputElement>) => {
         setToSAndPNAccepted(event.target.checked);
    };
    

    return(
        <form>
            <div style={{padding:10}}>
                <EntryField 
                    inputValue={props.inputValue} 
                    handleInputValueChange={handleInputValueChange} 
                    isInputValid={!usedForm || props.isInputValid}
                />
                <Grid container>
                    <Grid item xs={12} paddingTop={1}>
                        {props.showError &&
                            <Alert severity="error">
                                <AlertTitle>Error occured</AlertTitle>
                                Oh no an unexpected error occured - please try analysing again, if the error percists please contact us and we will help you.
                            </Alert>
                        }
                    </Grid>
                
                    <Grid item xs={12}>
                        {usedForm && !props.isInputValid && !props.resultLoading &&
                        <Typography color="error">
                            {props.helperText}
                        </Typography>
                        }
                    </Grid>
                    <Grid item xs={9} paddingTop={1}>
                        <Checkbox checked={toSAndPNAccepted} onChange={handleChangeToSAndPN}/> I have read, understood and accept the <Link to={'/termsOfService'}>Terms of Service</Link> & <Link to={'/privacyNotice'}>Privacy Policy</Link>.
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end" paddingTop={1}>
                        {!props.resultLoading &&
                            <Tooltip title={toSAndPNAccepted == false ? "Make sure to read, understood and accepted the Terms of Service and Privacy Policy" : ""}>
                                <div> 
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="medium"
                                        endIcon={<SendIcon />}
                                        onClick={handleSubmission}
                                        disabled={!toSAndPNAccepted || !props.isInputValid}
                                    >
                                        Analyse
                                    </Button>
                                </div>
                            </Tooltip>
                        }
                        {props.resultLoading &&
                        <CircularProgress color="secondary"/>
                        }
                    </Grid>
                </Grid>
                
            </div>
        </form>
    );
}

export default EntryForm